import { yupResolver } from '@hookform/resolvers/yup'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { Loader } from '@positivote/design-system/components/Loader'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormSelect } from '@positivote/design-system/components-form/Select'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import { debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { ClassroomDataStepForm, ClassroomStepperState } from '@/modules/hub/classroom/contracts'
import { useAvailableClassroom, useListClassLevel } from '@/modules/hub/classroom/hooks'
import { classroomDataValidationSchema } from '@/modules/hub/classroom/validations'

interface ClassroomDataStepProps {
  stepState: Partial<ClassroomStepperState['classroomData']>
  setStepState: (stepperState: Partial<ClassroomStepperState['classroomData']>) => void
  isLoading: boolean
  onChangeIsDirty: (isDirty: boolean) => void
}

export const ClassroomDataStep = forwardRef(function ClassroomDataStep(
  { setStepState, isLoading, onChangeIsDirty, stepState }: ClassroomDataStepProps,
  ref
) {
  const regexSpace = /\s/
  const regexSpecialCharacters = /[^\w\s]/
  const [isLoadingCode, setIsLoadingCode] = useState(false)
  const [isLoadingName, setIsLoadingName] = useState(false)
  const { profile } = useAuth()
  const location = useLocation()
  const locationState = location.state as {
    schoolYear: { name: string; id: number }
  } | null
  const [classroomParams, setClassroomParams] = useState<{ name?: string; code?: string }>({
    name: undefined,
    code: undefined
  })
  const minSearchLength = 3

  const { control, formState, setError, clearErrors, handleSubmit, reset, watch } =
    useForm<ClassroomDataStepForm>({
      mode: 'onSubmit',
      resolver: yupResolver(classroomDataValidationSchema)
    })

  const listClassLevel = useListClassLevel({
    model: {
      institutionId: profile?.organizationId
    },
    queryOptions: {
      enabled: !!profile
    }
  })

  const isNameValid = classroomParams.name && classroomParams.name.length >= minSearchLength
  const isCodeValid = classroomParams.code && classroomParams.code.length >= minSearchLength
  const isSchoolYearValid = !!locationState?.schoolYear.id
  const isStepFormNameDifferent = stepState.form?.name !== classroomParams.name
  const isStepFormCodeDifferent = stepState.form?.code !== classroomParams.code

  const shouldEnableName = Boolean(isNameValid && isSchoolYearValid && isStepFormNameDifferent)
  const shouldEnableCode = Boolean(isCodeValid && isSchoolYearValid && isStepFormCodeDifferent)

  const availableClassroomName = useAvailableClassroom({
    model: {
      name:
        classroomParams.name && classroomParams.name.length >= minSearchLength
          ? classroomParams.name
          : undefined,

      schoolYearId: locationState?.schoolYear.id
    },
    queryOptions: {
      staleTime: 0,
      enabled: shouldEnableName
    },
    onSuccess: (data) => {
      if (classroomParams.name) {
        if (data.name === 'unavailable' && classroomParams.name !== stepState.form?.name) {
          setIsLoadingName(false)
          setError('name', {
            message:
              i18n().modules.hub.classroom.pages.form.stepper.classroomData.validators
                .nameDisciplineExist
          })
        } else {
          setIsLoadingName(false)
          clearErrors('name')
        }
      }
    }
  })

  const availableClassroomCode = useAvailableClassroom({
    model: {
      code:
        classroomParams.code && classroomParams.code.length >= minSearchLength
          ? classroomParams.code
          : undefined,
      schoolYearId: locationState?.schoolYear.id
    },
    queryOptions: {
      staleTime: 0,
      enabled: shouldEnableCode
    },
    onSuccess: (data) => {
      if (classroomParams.code) {
        if (data.code === 'unavailable' && classroomParams.name !== stepState.form?.code) {
          setIsLoadingCode(false)
          setError('code', {
            message:
              i18n().modules.hub.classroom.pages.form.stepper.classroomData.validators
                .codeDisciplineExist
          })
        } else {
          setIsLoadingCode(false)
          clearErrors('code')
        }
      }
    }
  })

  const hasError = !!Object.keys(formState.errors).length
  const isDirty = watch('name') || watch('code') || watch('associatedSchoolYearId')

  function handleChangeSearchName(event: React.ChangeEvent<HTMLInputElement>): void {
    const search = event.target.value || undefined
    debounceEvent(() => {
      setIsLoadingName(true)
      setClassroomParams((oldData) => ({ ...oldData, name: search }))
    })()
  }

  function handleChangeSearchCode(event: React.ChangeEvent<HTMLInputElement>): void {
    const searchCode = event.target.value || undefined
    debounceEvent(() => {
      if (regexSpace.test(watch('code')) || regexSpecialCharacters.test(watch('code'))) {
        setError('code', {
          message:
            i18n().modules.hub.classroom.pages.form.stepper.classroomData.validators
              .invalidCharacterCode
        })
      } else {
        clearErrors('code')
        setIsLoadingCode(true)
        setClassroomParams((oldData) => ({ ...oldData, code: searchCode }))
      }
    })()
  }

  const validateDataForm = useCallback(async (): Promise<ClassroomDataStepForm | null> => {
    return new Promise((resolve) => {
      void handleSubmit(
        (data) => resolve(data),
        () => resolve(null)
      )()
    })
  }, [handleSubmit])

  useImperativeHandle(ref, () => ({ validateDataForm }), [validateDataForm])

  useEffect(() => {
    if (isDirty) {
      onChangeIsDirty(!!isDirty)
    } else {
      onChangeIsDirty(false)
    }
    // DOCS: only render when isDirty
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  useEffect(() => {
    setStepState({
      ...stepState,
      hasError,
      canGoNext:
        !hasError && !availableClassroomName.isFetching && !availableClassroomCode.isFetching
    })
    // DOCS: only render when hasError
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError, availableClassroomName.isFetching, availableClassroomCode.isFetching])

  useEffect(() => {
    if (stepState.form) {
      reset({
        code: stepState.form.code,
        associatedSchoolYearId: stepState.form.associatedSchoolYearId,
        name: stepState.form.name
      })
    }
  }, [reset, stepState.form])

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '$lg',
        minHeight: isLoading ? 180 : 'unset'
      }}
    >
      {isLoading ? (
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            padding: '$lg',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Loader size={80} />
        </Div>
      ) : (
        <>
          <Typography variant="bodyMedium" css={{ color: '$on-surface-variant' }}>
            {i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.title}
          </Typography>

          <Grid spacing="$md">
            <FormSelect
              placement="auto"
              required
              control={control}
              name="associatedSchoolYearId"
              optionKeyField="id"
              optionTitleField="fullNameClassLevelFormatted"
              gridProps={{ xl: 6 }}
              options={listClassLevel.data?.registers ?? []}
              variant="outlined"
              label={
                i18n().modules.hub.classroom.pages.form.stepper.classroomData.associatedSchoolYear
              }
              onChange={(options) =>
                setStepState({
                  ...stepState,
                  associatedSchoolYearName: options!.fullNameClassLevelFormatted
                })
              }
              errorText={formState.errors.associatedSchoolYearId?.message}
            />
          </Grid>
          <Grid spacing="$md">
            <FormTextField
              required
              control={control}
              name="name"
              gridProps={{ xl: 6 }}
              variant="outlined"
              label={i18n().modules.hub.classroom.pages.form.stepper.classroomData.name}
              inputProps={{
                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeSearchName(event)
              }}
              trailingIcon={
                availableClassroomName.isFetching && isLoadingName
                  ? {
                      icon: (props) => <Loader {...props} fill="$primary" />,
                      changeIconOnError: false
                    }
                  : undefined
              }
              errorText={formState.errors.name?.message}
            />
            <FormTextField
              required
              control={control}
              name="code"
              gridProps={{ xl: 6 }}
              variant="outlined"
              label={i18n().modules.hub.classroom.pages.form.stepper.classroomData.code}
              inputProps={{
                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeSearchCode(event)
              }}
              trailingIcon={
                availableClassroomCode.isFetching && isLoadingCode
                  ? {
                      icon: (props) => <Loader {...props} fill="$primary" />,
                      changeIconOnError: false
                    }
                  : undefined
              }
              errorText={formState.errors.code?.message}
            />
          </Grid>
        </>
      )}
    </Div>
  )
})
