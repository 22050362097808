import { cellMask, convertDateParsedTime, cpfMask } from '@/common/helpers'
import { i18n } from '@/common/i18n'

import {
  AcademicSyncFormatted,
  ListAcademicSyncApiResult,
  ListExportAcademicSyncApiResult,
  ListExportAcademicSyncFormatted,
  ShowAcademicSync,
  ShowAcademicSyncApi,
  ShowAcademicSyncFormatted
} from './contracts'

export const dataExports = [
  { label: 'Turmas', key: 'turmas' },
  { label: 'Alunos', key: 'alunos' },
  { label: 'Disciplinas', key: 'disciplinas' },
  { label: 'Colaboradores', key: 'colaboradores' },
  { label: 'Professores', key: 'professores' },
  { label: 'Vínculo de estudantes', key: 'ensalamento_alunos' },
  { label: 'Vínculo de professores', key: 'ensalamento_professores' },
  { label: 'Responsáveis', key: 'responsavel_alunos' }
]

export const statusAcademicSync = {
  PROCESS_SUCCESS: i18n().modules.hub.academicSync.pages.list.messageStatus.success,
  PROCESSED_WITH_ERROS: i18n().modules.hub.academicSync.pages.list.messageStatus.warning,
  PROCESS_ERROR: i18n().modules.hub.academicSync.pages.list.messageStatus.error,
  ADAPTER_ERROR: i18n().modules.hub.academicSync.pages.list.messageStatus.error
}

export function academicSyncFormatSanitizer(
  academicSyncs: ListAcademicSyncApiResult['data']
): AcademicSyncFormatted[] {
  return academicSyncs.map((academicSync) => ({
    id: academicSync.id,
    filename: academicSync.filename,
    createdAt: academicSync.created_at,
    schoolYearTitle: academicSync.school_year_title,
    statusFormatted: statusAcademicSync[academicSync.status as keyof typeof statusAcademicSync],
    status: academicSync.status,
    userFullname: academicSync.user_fullname,
    totalEnrollments: academicSync.total_enrollments,
    totalUsers: academicSync.total_users,
    createdAtFormatted: new Date(academicSync.created_at).toLocaleString(undefined, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }),
    timeFormatted: convertDateParsedTime(academicSync.created_at)
  }))
}

export function showAcademicSyncFormatSanitizerStudent(
  showAcademicSync: ShowAcademicSyncApi['student']
): ShowAcademicSync['student'] {
  return {
    total: showAcademicSync.total,
    success: showAcademicSync.success,
    data: showAcademicSync.data.map((data) => ({
      message: data.message,
      code: data.code,
      status: data.status,
      line: data.line,
      instance: {
        code: data.instance.code,
        name: data.instance.name,
        birthday: data.instance.birthday
          ? new Date(data.instance.birthday).toLocaleString(undefined, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            })
          : '-',
        login: data.instance.login,
        roleCode: data.instance.role_code,
        institutionId: data.instance.institution_id,
        genre: data.instance.genre ?? '-',
        email: data.instance.email ?? '-',
        document: data.instance.document ? cpfMask(data.instance.document) : '-',
        operation: data.instance.operation,
        systemInstitutionId: data.instance.system_institution_id,
        systemId: data.instance.system_id
      }
    }))
  }
}

export function showAcademicSyncFormatSanitizerDiscipline(
  showAcademicSync: ShowAcademicSyncApi['course']
): ShowAcademicSync['course'] {
  return {
    total: showAcademicSync.total,
    success: showAcademicSync.success,
    data: showAcademicSync.data.map((discipline) => ({
      code: discipline.code,
      message: discipline.message,
      status: discipline.status,
      line: discipline.line,
      instance: {
        code: discipline.instance.code,
        name: discipline.instance.name,
        disciplineBase: discipline.instance.discipline_base,
        institutionId: discipline.instance.institution_id,
        systemInstitutionId: discipline.instance.system_institution_id,
        operation: discipline.instance.operation,
        systemId: discipline.instance.system_id
      }
    }))
  }
}

export function showAcademicSyncFormatSanitizerSchoolClass(
  showAcademicSync: ShowAcademicSyncApi['classes']
): ShowAcademicSync['classes'] {
  return {
    total: showAcademicSync.total,
    success: showAcademicSync.success,
    data: showAcademicSync.data.map((schoolClass) => ({
      code: schoolClass.code,
      message: schoolClass.message,
      status: schoolClass.status,
      line: schoolClass.line,
      instance: {
        code: schoolClass.instance.code,
        name: schoolClass.instance.name,
        levelName: schoolClass.instance.level_name,
        institutionId: schoolClass.instance.institution_id,
        levelCode: schoolClass.instance.level_code,
        levelId: schoolClass.instance.level_id,
        operation: schoolClass.instance.operation,
        schoolYearId: schoolClass.instance.school_year_id,
        systemSchoolYearId: schoolClass.instance.system_school_year_id,
        systemInstitutionId: schoolClass.instance.system_institution_id,
        systemId: schoolClass.instance.system_id
      }
    }))
  }
}

export function showAcademicSyncFormatSanitizerGuardian(
  showAcademicSync: ShowAcademicSyncApi['guardian']
): ShowAcademicSync['guardian'] {
  return {
    total: showAcademicSync.total,
    success: showAcademicSync.success,
    data: showAcademicSync.data.map((data) => ({
      message: data.message,
      code: data.code,
      status: data.status,
      line: data.line,
      instance: {
        code: data.instance.code,
        name: data.instance.name,
        birthday: data.instance.birthday
          ? new Date(data.instance.birthday).toLocaleString(undefined, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            })
          : '-',
        login: data.instance.login,
        roleCode: data.instance.role_code,
        institutionId: data.instance.institution_id,
        genre: data.instance.genre ?? '-',
        email: data.instance.email ?? '-',
        document: cpfMask(data.instance.document ?? ''),
        operation: data.instance.operation,
        systemInstitutionId: data.instance.system_institution_id,
        systemId: data.instance.system_id
      }
    }))
  }
}

export function showAcademicSyncFormatSanitizerTeacher(
  showAcademicSync: ShowAcademicSyncApi['teacher']
): ShowAcademicSync['teacher'] {
  return {
    total: showAcademicSync.total,
    success: showAcademicSync.success,
    data: showAcademicSync.data.map((data) => ({
      message: data.message,
      code: data.code,
      status: data.status,
      line: data.line,
      instance: {
        code: data.instance.code,
        name: data.instance.name,
        birthday: data.instance.birthday
          ? new Date(data.instance.birthday).toLocaleString(undefined, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            })
          : '-',
        login: data.instance.login,
        roleCode: data.instance.role_code,
        institutionId: data.instance.institution_id,
        genre: data.instance.genre ?? '-',
        email: data.instance.email,
        document: cpfMask(data.instance.document),
        phone: cellMask(data.instance.phone ?? ''),
        operation: data.instance.operation,
        systemInstitutionId: data.instance.system_institution_id,
        systemId: data.instance.system_id
      }
    }))
  }
}

export function showAcademicSyncFormatSanitizerEnrollmentTeacher(
  showAcademicSync: ShowAcademicSyncApi['enrollment_teacher']
): ShowAcademicSync['enrollmentTeacher'] {
  return {
    total: showAcademicSync.total,
    success: showAcademicSync.success,
    data: showAcademicSync.data.map((data) => ({
      code: data.code,
      message: data.message,
      status: data.status,
      line: data.line,
      instance: {
        sectionCode: data.instance.section_code,
        courseCode: data.instance.course_code,
        teacherCode: data.instance.teacher_code,
        institutionId: data.instance.institution_id,
        schoolYearId: data.instance.school_year_id,
        operation: data.instance.operation,
        systemSchoolYearId: data.instance.system_school_year_id,
        systemInstitutionId: data.instance.system_institution_id,
        systemId: data.instance.system_id
      }
    }))
  }
}

export function showAcademicSyncFormatSanitizerStaff(
  showAcademicSync: ShowAcademicSyncApi['staff']
): ShowAcademicSync['staff'] {
  return {
    total: showAcademicSync.total,
    success: showAcademicSync.success,
    data: showAcademicSync.data.map((data) => ({
      message: data.message,
      code: data.code,
      status: data.status,
      line: data.line,
      instance: {
        code: data.instance.code,
        name: data.instance.name,
        birthday: data.instance.birthday
          ? new Date(data.instance.birthday).toLocaleString(undefined, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            })
          : '-',
        login: data.instance.login,
        roleCode: data.instance.role_code,
        institutionId: data.instance.institution_id,
        genre: data.instance.genre ?? '-',
        email: data.instance.email,
        document: cpfMask(data.instance.document ?? ''),
        operation: data.instance.operation,
        systemInstitutionId: data.instance.system_institution_id,
        systemId: data.instance.system_id
      }
    }))
  }
}

export function showAcademicSyncFormatSanitizerEnrollmentStudent(
  showAcademicSync: ShowAcademicSyncApi['enrollment_student']
): ShowAcademicSync['enrollmentStudent'] {
  return {
    total: showAcademicSync.total,
    success: showAcademicSync.success,
    data: showAcademicSync.data.map((data) => ({
      code: data.code,
      message: data.message,
      status: data.status,
      line: data.line,
      instance: {
        systemId: data.instance.system_id,
        sectionCode: data.instance.section_code,
        studentCode: data.instance.student_code,
        institutionId: data.instance.institution_id,
        schoolYearId: data.instance.school_year_id,
        operation: data.instance.operation,
        systemSchoolYearId: data.instance.system_school_year_id,
        systemInstitutionId: data.instance.system_institution_id
      }
    }))
  }
}

export function showAcademicSyncFormatSanitizer(
  showAcademicSync: ShowAcademicSyncApi
): ShowAcademicSyncFormatted {
  return {
    endOfProcessing: showAcademicSync.end_of_processing,
    filename: showAcademicSync.filename,
    startOfProcessing: showAcademicSync.start_of_processing,
    status: showAcademicSync.status,
    schoolYearTitle: showAcademicSync.school_year_title,
    totalEnrollments: showAcademicSync.total_enrollments,
    endOfProcessingFormatted: new Date(showAcademicSync.start_of_processing).toLocaleString(
      undefined,
      {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }
    ),
    timeFormatted: convertDateParsedTime(showAcademicSync.start_of_processing.split('T')[1]),
    statusFormatted: statusAcademicSync[showAcademicSync.status as keyof typeof statusAcademicSync],
    student: showAcademicSyncFormatSanitizerStudent(showAcademicSync.student),
    course: showAcademicSyncFormatSanitizerDiscipline(showAcademicSync.course),
    classes: showAcademicSyncFormatSanitizerSchoolClass(showAcademicSync.classes),
    guardian: showAcademicSyncFormatSanitizerGuardian(showAcademicSync.guardian),
    teacher: showAcademicSyncFormatSanitizerTeacher(showAcademicSync.teacher),
    enrollmentTeacher: showAcademicSyncFormatSanitizerEnrollmentTeacher(
      showAcademicSync.enrollment_teacher
    ),
    staff: showAcademicSyncFormatSanitizerStaff(showAcademicSync.staff),
    enrollmentStudent: showAcademicSyncFormatSanitizerEnrollmentStudent(
      showAcademicSync.enrollment_student
    ),
    totalUsers: showAcademicSync.total_users,
    userFullname: showAcademicSync.user_fullname
  }
}

export function listAcademicSyncSanitizer(
  listAcademicSync: ListExportAcademicSyncApiResult[]
): ListExportAcademicSyncFormatted[] {
  return listAcademicSync.map((academicSync) => ({
    id: academicSync.id,
    arquivo: academicSync.arquivo,
    dtCadastro: academicSync.dt_cadastro,
    filtro: academicSync.filtro,
    idInstituicao: academicSync.id_instituicao,
    idProfile: academicSync.id_profile,
    schoolYearName: academicSync.school_year_name,
    status: academicSync.status,
    dateFormatted: new Date(academicSync.dt_cadastro).toLocaleString(undefined, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }),
    timeFormatted: academicSync.dt_cadastro.split('T')[1],
    filtroFormatted: academicSync.filtro.split(',').map((key) => {
      const filterMapping = dataExports.find((mapping) => mapping.key === key.trim())
      return filterMapping ? filterMapping.label : key.trim()
    })
  }))
}
