import { Badges } from '@positivote/design-system/components/Badges'
import { Button } from '@positivote/design-system/components/Button'
import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Select } from '@positivote/design-system/components/Select'
import { Typography } from '@positivote/design-system/components/Typography'
import { ArrowForwardIcon } from '@positivote/design-system/icons/ArrowForward'
import { CalendarTodayIcon } from '@positivote/design-system/icons/CalendarToday'
import { CancelIcon } from '@positivote/design-system/icons/Cancel'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { OrganizationKind } from '@/modules/hub/organizations/contracts'
import {
  PromoteSchoolYearServiceParams,
  SchoolYearFormatted
} from '@/modules/hub/school-year/contracts'
import { useListSchoolClasses, usePromoteSchoolYear } from '@/modules/hub/school-year/hooks'

export function SchoolYearPromotion(): JSX.Element {
  const navigate = useNavigate()
  const { profile } = useAuth()
  const promotion = usePromoteSchoolYear()
  const location = useLocation()
  const locationState = location.state as SchoolYearFormatted | null

  const [promotionParams, setPromotionParams] = useState<PromoteSchoolYearServiceParams>({
    origin: '',
    dest: '',
    institutionId: '',
    promotions: []
  })
  const params = useParams()

  const listClasses = useListSchoolClasses({
    model: {
      termId: params.id
    }
  })

  const listActiveClasses = useListSchoolClasses({
    model: {
      institutionId: locationState?.institutionId as unknown as string
    },
    queryOptions: {
      enabled: !!location
    },
    onError: () => {
      navigate(-1)
    }
  })

  const isLoading = listClasses.isLoading && listActiveClasses.isLoading

  function handleSelect(id: string, index: number): void {
    setPromotionParams((oldData) => {
      if (id === 'undefined') {
        return {
          ...oldData,
          promotions: oldData.promotions.filter(
            (promo) => promo.to !== listClasses.data!.registers[index].code
          )
        }
      } else {
        return {
          ...oldData,
          origin: listActiveClasses.data!.code,
          dest: listClasses.data!.code,
          institutionId: locationState!.institutionId,
          promotions: [
            ...oldData.promotions,
            { from: id || '', to: listClasses.data!.registers[index].code }
          ]
        }
      }
    })
  }

  function handleSave(): void {
    promotion.mutate({
      model: promotionParams,
      onSuccess: () => {
        navigate(-1)
      }
    })
  }

  const isSchool = profile?.organization.kindId === OrganizationKind.SCHOOL

  return (
    <Main
      css={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowX: 'hidden'
      }}
    >
      <AppBar
        title={i18n().modules.hub.schoolYear.pages.promotion.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.schoolYear.pages.list.appBar.breadcrumbs.overview,
            onClick: () => {
              navigate(-2)
            }
          },
          {
            label:
              i18n().modules.hub.schoolYear.pages.list.appBar.breadcrumbs.educationLevelsAndClasses,
            onClick: () => {
              navigate(-1)
            }
          },
          {
            label: i18n().modules.hub.schoolYear.pages.list.appBar.breadcrumbs.schoolYear
          },
          {
            label: locationState!.title
          }
        ]}
      />
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '$lg',
          overflowY: 'auto',
          '@sm': { padding: '$md' }
        }}
      >
        {isLoading ? (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              padding: '$lg',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Loader size={80} />
          </Div>
        ) : (
          <Div
            css={{
              display: 'flex',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$lg',
              borderColor: '$outline-variant',
              padding: '$lg',
              gap: '$lg'
            }}
          >
            <IconWrapper size={72} css={{ backgroundColor: '$primary-container' }}>
              <CalendarTodayIcon size={40} fill="$on-primary-container" />
            </IconWrapper>
            <Div css={{ display: 'flex', flexDirection: 'column', gap: '$md', flex: 1 }}>
              <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                {locationState?.title}
              </Typography>
              <Divider />
              <Div
                css={{
                  display: 'flex',
                  gap: '$lg',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                {!isSchool && (
                  <Div css={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                    <Chip
                      variant="filled"
                      color="neutral"
                      label={locationState?.schoolName ?? ''}
                    />
                  </Div>
                )}

                <Div css={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                  <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
                    {i18n().modules.hub.schoolYear.pages.promotion.initialDate}
                  </Typography>
                  <Typography variant="bodyLarge">{locationState?.startDate}</Typography>
                </Div>
                <Div css={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                  <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
                    {i18n().modules.hub.schoolYear.pages.promotion.finalDate}
                  </Typography>
                  <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                    {locationState?.endDate}
                  </Typography>
                </Div>
              </Div>
              <Divider />
              <Typography variant="titleMedium">
                {i18n().modules.hub.schoolYear.pages.promotion.pageTitle}
              </Typography>
              <Div
                css={{
                  display: 'flex',
                  gap: '$md',
                  flexDirection: 'column'
                }}
              >
                <Div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    borderWidth: '$thin',
                    borderStyle: 'solid',
                    borderRadius: '$xl',
                    borderColor: '$outline-variant',
                    alignItems: 'center',
                    padding: '$lg',
                    gap: '$md',
                    overflow: 'auto',
                    minHeight: 350
                  }}
                >
                  <Div css={{ display: 'flex', width: '100%', gap: '$2xs', alignItems: 'center' }}>
                    <Div
                      css={{
                        backgroundColor: '$surface-variant',
                        flex: 1,
                        gap: '$md',
                        borderRadius: '$md',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '$xs $md'
                      }}
                    >
                      <Typography variant="labelSmall" css={{ color: '$on-surface-variant' }}>
                        {i18n().modules.hub.schoolYear.pages.promotion.origin}
                      </Typography>
                      <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs' }}>
                        <Badges fill="$success" />
                        <Typography variant="bodyMedium">
                          {listActiveClasses.data?.title}
                        </Typography>
                      </Div>
                    </Div>
                    <ArrowForwardIcon size={16} />
                    <Div
                      css={{
                        backgroundColor: '$surface-variant',

                        gap: '$md',
                        borderRadius: '$md',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1,
                        padding: '$xs $md'
                      }}
                    >
                      <Typography variant="labelSmall" css={{ color: '$on-surface-variant' }}>
                        {i18n().modules.hub.schoolYear.pages.promotion.destiny}
                      </Typography>
                      <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs' }}>
                        <Badges fill="$on-surface-variant" />
                        <Typography variant="bodyMedium">{locationState?.title}</Typography>
                      </Div>
                    </Div>
                  </Div>

                  <Div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '$2xs',
                      overflow: 'auto',
                      maxHeight: 350,
                      flex: 1,
                      width: '100%'
                    }}
                  >
                    {listClasses.data?.registers.map((classe, index) => (
                      <Div
                        key={classe.id}
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '$2xs'
                        }}
                      >
                        <Select
                          variant="filled"
                          label={i18n().modules.hub.schoolYear.pages.promotion.selectOriginClass}
                          optionKeyField="code"
                          optionTitleField="name"
                          options={listActiveClasses.data?.registers ?? []}
                          css={{ flex: 1 }}
                          onChange={(option) => handleSelect(String(option?.code), index)}
                          hasNoneOption
                        />

                        <ArrowForwardIcon size={16} />
                        <Div css={{ flex: 1 }}>
                          <Div
                            css={{
                              backgroundColor: '$surface-1',
                              padding: '$xs $md',
                              gap: '$md',
                              borderRadius: '$md',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Typography variant="labelSmall" css={{ color: '$on-surface-variant' }}>
                              {classe.name} ({classe.students}
                              {i18n().modules.hub.schoolYear.pages.promotion.students})
                            </Typography>
                          </Div>
                        </Div>
                      </Div>
                    ))}
                  </Div>
                </Div>
                <Div
                  css={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '$lg'
                  }}
                >
                  <Button
                    onClick={() => navigate(-1)}
                    LeadingIcon={<CancelIcon size={18} />}
                    variant="text"
                    isLoading={promotion.isPending}
                  >
                    {i18n().modules.hub.schoolYear.pages.promotion.cancel}
                  </Button>
                  <Button
                    disabled={!promotionParams.promotions.length}
                    onClick={() => handleSave()}
                    LeadingIcon={<CheckCircleIcon size={18} />}
                    variant="outlined"
                    isLoading={promotion.isPending}
                  >
                    {i18n().modules.hub.schoolYear.pages.promotion.save}
                  </Button>
                </Div>
              </Div>
            </Div>
          </Div>
        )}
      </Div>
    </Main>
  )
}
