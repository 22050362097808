import { Badges } from '@positivote/design-system/components/Badges'
import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import * as Dialog from '@positivote/design-system/components/Dialog'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { ProgressBar } from '@positivote/design-system/components/ProgressBar'
import { Select } from '@positivote/design-system/components/Select'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { AlertIcon } from '@positivote/design-system/icons/Alert'
import { CancelIcon } from '@positivote/design-system/icons/Cancel'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { DownloadIcon } from '@positivote/design-system/icons/Download'
import { ErrorIcon } from '@positivote/design-system/icons/Error'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { TodayIcon } from '@positivote/design-system/icons/Today'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import { STALE_TIME } from '@/common/constants/react-query'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { AccessTimeIcon } from '@/fixme/icons/AccessTime'
import { CloudDoneIcon } from '@/fixme/icons/CloudDone'
import { ManageSearchIcon } from '@/fixme/icons/ManageSearch'
import { SupervisedUserCircleIcon } from '@/fixme/icons/SupervisedUserCircle'
import { UploadIcon } from '@/fixme/icons/Upload'
import { FirstCard, FirstCardProps } from '@/modules/hub/academic-sync/components/FirstCard'
import { useImportAcademicSync, useListAcademicSync } from '@/modules/hub/academic-sync/hooks'
import { statusAcademicSync } from '@/modules/hub/academic-sync/sanitizers'
import { useAuth } from '@/modules/hub/auth/contexts'
import { OrganizationKind } from '@/modules/hub/organizations/contracts'
import { useListSchoolYear } from '@/modules/hub/school-year/hooks'

interface SelectOption {
  id: number
  icon: JSX.Element
  title: string
}

export function ListAcademicSync(): JSX.Element {
  changePageTitle(i18n().modules.hub.academicSync.pages.list.pageTitle)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedSchoolYear, setSelectedSchoolYear] = useState(0)
  const [selectedImportSchoolYear, setSelectedImportSchoolYear] = useState(0)
  const [refetchIntervalTime, setRefetchIntervalTime] = useState(0)
  const { profile } = useAuth()
  const location = useLocation()
  const locationState = location.state as { idSchool?: string; schoolName?: string } | undefined
  const isSchool = profile?.organization.kindId === OrganizationKind.SCHOOL
  const navigate = useNavigate()
  const importAcademicSync = useImportAcademicSync()
  const currentOrgId = locationState?.idSchool ?? profile?.organizationId

  const listSchoolYear = useListSchoolYear({
    model: {
      perPage: 100,
      institutionId: currentOrgId as unknown as string
    }
  })

  const filteredSchoolYearOptions = useMemo<SelectOption[]>(() => {
    return (
      listSchoolYear.data?.registers.map((year) => ({
        id: year.id,
        icon: <Badges fill={year.status === 'active' ? '$success' : '$neutral-70'} />,
        title: year.title
      })) ?? []
    )
  }, [listSchoolYear.data?.registers])

  const listSchoolYearFormatted = [
    { id: 0, title: i18n().modules.hub.academicSync.pages.list.allYear },
    ...(listSchoolYear.data?.registers ?? [])
  ]
  const currentInstitutionId = isSchool
    ? (profile.organizationId as unknown as number)
    : Number(locationState?.idSchool)

  const listFirstCard = useListAcademicSync({
    model: {
      institutionId: currentInstitutionId,
      page: 1,
      perPage: 1
    },
    queryOptions: {
      enabled: !!profile,
      refetchInterval: refetchIntervalTime
    }
  })

  const statusPercent = {
    READING: {
      label: i18n().modules.hub.academicSync.pages.list.readingInformation,
      percent: 35
    },
    READ_SUCCESS: {
      label: i18n().modules.hub.academicSync.pages.list.readingInformation,
      percent: 40
    },
    ADAPTING: {
      label: i18n().modules.hub.academicSync.pages.list.adaptingTables,
      percent: 65
    },
    PROCESSING: {
      label: i18n().modules.hub.academicSync.pages.list.processingData,
      percent: 95
    }
  }

  const isImporting = listFirstCard.data?.registers[0]?.status
    ? Object.keys(statusPercent).includes(
        listFirstCard.data.registers[0].status as keyof typeof statusPercent
      )
    : false

  const listAcademicSync = useListAcademicSync({
    model: {
      institutionId: currentInstitutionId,
      termId: selectedSchoolYear === 0 ? undefined : selectedSchoolYear
    },
    queryOptions: {
      enabled: !!profile
    }
  })
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleButtonClick = (): void => {
    fileInputRef.current?.click()
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedFile = event.target.files?.[0] || null
    handleImportAcademicSync(selectedFile)
  }

  function handleClose(): void {
    setIsOpen(false)
  }

  const isLoading = listAcademicSync.isLoading || listFirstCard.isLoading

  function handleImportAcademicSync(file: File | null): void {
    if (!file) {
      return
    }

    const formData = new FormData()
    formData.append('school_data_file', file, file.name)
    formData.append('term_id', String(selectedImportSchoolYear))

    importAcademicSync.mutate({
      model: {
        file: formData,
        institutionId: currentInstitutionId
      },
      onSuccess: () => {
        setIsOpen(false)
      }
    })
  }

  const firstCard = listFirstCard.data?.registers[0]
  const firstCardStatus = firstCard?.status as keyof typeof statusPercent
  const newListAcademicSync =
    selectedSchoolYear !== 0
      ? listAcademicSync.data?.registers
      : listAcademicSync.data?.registers.slice(1)

  const firstCardData: FirstCardProps = {
    data: {
      id: firstCard?.id,
      schoolYearTitle: firstCard?.schoolYearTitle,
      fileName: firstCard?.filename,
      processed: firstCard?.createdAtFormatted,
      statusFile: firstCard?.statusFormatted,
      submittedBy: firstCard?.userFullname,
      time: firstCard?.timeFormatted,
      totalLinks: firstCard?.totalEnrollments,
      totalUsers: firstCard?.totalUsers
    }
  }

  useEffect(() => {
    setSelectedImportSchoolYear(listSchoolYear.data?.registers[0].id ?? 0)
  }, [listSchoolYear.data?.registers])

  useEffect(() => {
    const tenMileSeconds = 10000
    if (firstCard?.status) {
      if (Object.keys(statusPercent).includes(firstCard.status)) {
        setRefetchIntervalTime(tenMileSeconds)
      } else {
        setRefetchIntervalTime(STALE_TIME)
      }
    }
  }, [listFirstCard.data?.registers])
  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <Dialog.Container xl={4} lg={5} md={6} isOpen={isOpen} onCancel={handleClose}>
        <Dialog.Header align="center">
          <Dialog.HeaderTitle>
            {i18n().modules.hub.academicSync.pages.list.dialog.title}
          </Dialog.HeaderTitle>
          <Dialog.HeaderCloseButton onCancel={handleClose} />
        </Dialog.Header>
        <Dialog.Content align="center">
          <Dialog.ContentText css={{ textAlign: 'center' }}>
            {i18n().modules.hub.academicSync.pages.list.dialog.ContentText}
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Content align="center">
          <Select
            label={i18n().modules.hub.academicSync.pages.list.dialog.selectLabel}
            variant="outlined"
            leadingElementField="icon"
            optionKeyField="id"
            optionTitleField="title"
            options={filteredSchoolYearOptions}
            value={selectedImportSchoolYear}
            onChange={(event) => setSelectedImportSchoolYear(event!.id)}
            css={{ width: '100%' }}
          />
          <input
            onChange={handleFileChange}
            style={{ display: 'none' }}
            type="file"
            accept=".xlsx"
            ref={fileInputRef}
          />
          <Dialog.ContentText css={{ textAlign: 'center' }}>
            {i18n().modules.hub.academicSync.pages.list.dialog.contentTextFooter}
          </Dialog.ContentText>
          <Dialog.Footer align="center">
            <Dialog.FooterRefuseButton
              disabled={importAcademicSync.isPending}
              LeadingIcon={<CancelIcon size={18} />}
              onClick={handleClose}
            >
              {i18n().modules.hub.academicSync.pages.list.dialog.footerRefuseButton}
            </Dialog.FooterRefuseButton>
            <Dialog.FooterAcceptButton
              isLoading={importAcademicSync.isPending}
              onClick={handleButtonClick}
              LeadingIcon={<CheckCircleIcon size={18} />}
              type="submit"
            >
              {i18n().modules.hub.academicSync.pages.list.dialog.footerAcceptButton}
            </Dialog.FooterAcceptButton>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Container>
      <AppBar
        title={i18n().modules.hub.academicSync.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
        trailingElement={
          <>
            <Button
              onClick={() =>
                window.open(
                  'https://cdn-hubeducacional.s3.us-east-2.amazonaws.com/cadastro-2.1.3.20231110.xlsx',
                  '_blank'
                )
              }
              LeadingIcon={<DownloadIcon />}
              variant="outlined"
            >
              {i18n().modules.hub.academicSync.pages.list.appBar.buttonDownloadSpreadsheet}
            </Button>
            {!!listFirstCard.data?.registers.length && (
              <Button
                disabled={isImporting}
                onClick={() => setIsOpen(true)}
                LeadingIcon={<UploadIcon />}
                variant="tonal"
              >
                {i18n().modules.hub.academicSync.pages.list.appBar.buttonImportSpreadsheet}
              </Button>
            )}
          </>
        }
        breadcrumbItems={
          isSchool
            ? [
                {
                  label: i18n().modules.hub.academicSync.pages.list.appBar.breadcrumbs.overview,
                  onClick: () => {
                    navigate(-1)
                  }
                },
                {
                  label: i18n().modules.hub.academicSync.pages.list.appBar.breadcrumbs.myImports
                }
              ]
            : [
                {
                  label: i18n().modules.hub.disciplines.pages.list.appBar.breadcrumbs.overview,
                  onClick: () => {
                    navigate(-1)
                  }
                },
                {
                  label: i18n().modules.hub.academicSync.pages.list.appBar.breadcrumbs.myImports
                },
                {
                  label: locationState?.schoolName ?? '',
                  onClick: () => {
                    navigate(-1)
                  }
                }
              ]
        }
      />
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '$lg',
          overflowY: 'auto',
          '@sm': { padding: '$md' }
        }}
      >
        {!listFirstCard.data?.registers.length ? (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              padding: '$lg',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {isLoading && <Loader data-testid="Loader-Container-DisciplineList" size={80} />}
            {!isLoading && (
              <Div
                css={{ display: 'flex', flexDirection: 'column', gap: '$md', alignItems: 'center' }}
              >
                <EmptyList
                  title={i18n().modules.hub.academicSync.pages.list.emptyList.title}
                  subTitle={i18n().modules.hub.academicSync.pages.list.emptyList.subTitle}
                />
                <Button
                  onClick={() => setIsOpen(true)}
                  LeadingIcon={<UploadIcon />}
                  variant="tonal"
                >
                  {i18n().modules.hub.academicSync.pages.list.importData}
                </Button>
              </Div>
            )}
          </Div>
        ) : (
          <Div css={{ display: 'flex', flexDirection: 'column', gap: '$lg' }}>
            <Div css={{ display: 'flex', flexDirection: 'column', gap: '$sm' }}>
              <Typography variant="titleLarge">
                {i18n().modules.hub.academicSync.pages.list.importInUse}
              </Typography>
              {Object.keys(statusPercent).includes(firstCard!.status) ? (
                <BaseCard
                  css={{
                    padding: '24px',
                    borderRadius: '$md',
                    '& .BaseCard-StateLayer': { gap: '$md' }
                  }}
                >
                  <Div
                    css={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '$md'
                    }}
                  >
                    <Typography variant="titleMedium">{firstCard?.filename}</Typography>
                    <Divider />
                    <Div
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '$sm',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <SupervisedUserCircleIcon size={40} />
                      <Typography variant="bodyMedium">
                        {statusPercent[firstCardStatus].label}
                      </Typography>
                    </Div>
                    <ProgressBar
                      filledPercentage={statusPercent[firstCardStatus].percent}
                      css={{ width: 200 }}
                    />
                  </Div>
                </BaseCard>
              ) : (
                <FirstCard data={firstCardData.data} />
              )}
            </Div>
            {(!!newListAcademicSync?.length || selectedSchoolYear !== 0) && (
              <Div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  borderWidth: '$thin',
                  borderStyle: 'solid',
                  borderRadius: '$md',
                  borderColor: '$outline-variant',
                  padding: '$lg',
                  gap: '$md'
                }}
              >
                <Typography variant="titleLarge">
                  {i18n().modules.hub.academicSync.pages.list.importHistory}
                </Typography>
                <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs' }}>
                  <FilterListIcon size={18} />
                  <Typography
                    data-testid="Typography-titleFilter"
                    variant="titleMedium"
                    css={{ color: '$on-surface' }}
                  >
                    {i18n().modules.hub.academicSync.pages.list.filteringOptions}
                  </Typography>
                </Div>
                <Select
                  label={i18n().modules.hub.academicSync.pages.list.schoolYear}
                  variant="outlined"
                  optionKeyField="id"
                  optionTitleField="title"
                  value={selectedSchoolYear}
                  options={listSchoolYearFormatted}
                  onChange={(options) => {
                    setSelectedSchoolYear(options!.id)
                  }}
                  css={{ width: '300px' }}
                />
                <Divider />
                <Grid
                  css={{
                    display: 'flex',
                    opacity:
                      !listAcademicSync.isFetching && selectedSchoolYear !== 0
                        ? '$transparent'
                        : '$default'
                  }}
                >
                  <Grid xl={4} lg={3} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                    <Typography
                      data-testid="Typography-header-discipline"
                      variant="titleMedium"
                      lineClamp={1}
                      css={{ color: '$on-surface' }}
                    >
                      {i18n().modules.hub.academicSync.pages.list.file}
                    </Typography>
                  </Grid>

                  <Grid xl={3} lg={3}>
                    <Typography
                      data-testid="Typography-header-code"
                      variant="titleMedium"
                      lineClamp={1}
                      css={{ color: '$on-surface' }}
                    >
                      {i18n().modules.hub.academicSync.pages.list.shipping}
                    </Typography>
                  </Grid>
                  <Grid xl={2} lg={2}>
                    <Typography
                      data-testid="Typography-header-base-discipline"
                      variant="titleMedium"
                      lineClamp={1}
                      css={{ color: '$on-surface' }}
                    >
                      {i18n().modules.hub.academicSync.pages.list.user}
                    </Typography>
                  </Grid>
                  <Grid xl={1} lg={2}>
                    <Typography
                      data-testid="Typography-header-base-discipline"
                      variant="titleMedium"
                      lineClamp={1}
                      css={{ color: '$on-surface' }}
                    >
                      {i18n().modules.hub.academicSync.pages.list.schoolYear}
                    </Typography>
                  </Grid>
                  <Grid xl={1}>
                    <Typography
                      data-testid="Typography-header-base-discipline"
                      variant="titleMedium"
                      lineClamp={1}
                      css={{ color: '$on-surface' }}
                    >
                      {i18n().modules.hub.academicSync.pages.list.status}
                    </Typography>
                  </Grid>
                  <Grid xl={1}></Grid>
                </Grid>
                <UL
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '$sm',
                    minHeight: listAcademicSync.isFetching ? '250px' : 'auto',
                    ...(listAcademicSync.isFetching && {
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative'
                    })
                  }}
                >
                  {listAcademicSync.isFetching && <Loader size={80} />}

                  {!listAcademicSync.isFetching &&
                    !newListAcademicSync?.length &&
                    selectedSchoolYear !== 0 && <EmptySearch />}

                  {newListAcademicSync?.map((discipline, index) => (
                    <BaseCard
                      key={discipline.id}
                      data-testid={`BaseCard-Container-disciplineListItem-${index}`}
                      css={{
                        opacity: listAcademicSync.isFetching ? '$transparent' : '$default',
                        borderRadius: '$lg',
                        backgroundColor: '$surface-1',
                        '& .BaseCard-StateLayer': {
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '$lg',
                          padding: '$md',
                          height: '64px'
                        }
                      }}
                    >
                      <Grid css={{ flex: 1, alignItems: 'center', display: 'flex' }}>
                        <Grid
                          xl={4}
                          lg={3}
                          css={{ display: 'flex', alignItems: 'center', gap: '$md' }}
                        >
                          <CloudDoneIcon fill="$primary" />
                          <Typography
                            data-testid={`Typography-name-${index}`}
                            lineClamp={1}
                            variant="bodyMedium"
                            css={{ color: '$on-surface' }}
                          >
                            {discipline.filename}
                          </Typography>
                        </Grid>

                        <Grid xl={3} lg={3} css={{ display: 'flex', gap: '$md' }}>
                          <Div css={{ display: 'flex', gap: '$sm', alignItems: 'center' }}>
                            <TodayIcon />
                            <Typography
                              data-testid={`Typography-code-${index}`}
                              lineClamp={1}
                              variant="bodyMedium"
                              css={{ color: '$on-surface' }}
                            >
                              {discipline.createdAtFormatted}
                            </Typography>
                          </Div>
                          <Div css={{ display: 'flex', gap: '$sm', alignItems: 'center' }}>
                            <AccessTimeIcon />
                            <Typography
                              data-testid={`Typography-code-${index}`}
                              lineClamp={1}
                              variant="bodyMedium"
                              css={{ color: '$on-surface' }}
                            >
                              {discipline.timeFormatted}
                            </Typography>
                          </Div>
                        </Grid>
                        <Grid xl={2} lg={2}>
                          <Tooltip label={discipline.userFullname}>
                            <Div css={{ display: 'flex', gap: '$sm', alignItems: 'center' }}>
                              <IconWrapper
                                size="$lg"
                                css={{ backgroundColor: '$primary-container' }}
                              >
                                <PersonIcon size={18} />
                              </IconWrapper>
                              <Typography
                                data-testid={`Typography-courseBaseName-${index}`}
                                lineClamp={1}
                                variant="bodyMedium"
                                css={{ color: '$on-surface' }}
                              >
                                {discipline.userFullname}
                              </Typography>
                            </Div>
                          </Tooltip>
                        </Grid>
                        <Grid xl={1} lg={2}>
                          <Typography
                            data-testid={`Typography-courseBaseName-${index}`}
                            lineClamp={2}
                            variant="bodyMedium"
                            css={{ color: '$on-surface' }}
                          >
                            {discipline.schoolYearTitle}
                          </Typography>
                        </Grid>
                        <Grid xl={1}>
                          {discipline.statusFormatted === statusAcademicSync.PROCESS_SUCCESS && (
                            <Tooltip
                              label={
                                i18n().modules.hub.academicSync.pages.list.messageStatus.success
                              }
                            >
                              <CheckCircleIcon fill="$success" />
                            </Tooltip>
                          )}
                          {discipline.statusFormatted === statusAcademicSync.PROCESS_ERROR && (
                            <Tooltip
                              label={i18n().modules.hub.academicSync.pages.list.messageStatus.error}
                            >
                              <ErrorIcon css={{ pointerEvents: 'unset' }} fill="$critical" />
                            </Tooltip>
                          )}
                          {discipline.statusFormatted ===
                            statusAcademicSync.PROCESSED_WITH_ERROS && (
                            <Tooltip
                              label={
                                i18n().modules.hub.academicSync.pages.list.messageStatus.warning
                              }
                            >
                              <AlertIcon css={{ pointerEvents: 'unset' }} fill="$warning" />
                            </Tooltip>
                          )}
                        </Grid>
                        <Grid xl={1} lg={1} css={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <IconButton
                            onClick={() => navigate(`/data-management/my-imports/${discipline.id}`)}
                            variant="standard"
                            css={{ padding: '$none' }}
                          >
                            <ManageSearchIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </BaseCard>
                  ))}
                </UL>
              </Div>
            )}
          </Div>
        )}
      </Div>
    </Main>
  )
}
