import {
  GenderDictionaryValue,
  cellMask,
  convertDateToISO,
  cpfMask,
  genderDictionary,
  parseValidDateOrDefault,
  removeMask
} from '@/common/helpers'

import {
  CreateUserServiceParams,
  ListQrCodeUsersApiResult,
  ListShortStudent,
  ListShortStudentApiResult,
  ListTeacherApiResult,
  ListUserApiResult,
  NewPasswordApiResult,
  NewPasswordServiceResult,
  QrCodeUsers,
  ShortUser,
  ShortUserFormatted,
  Teacher,
  UserApi
} from './contracts'
import { UserDataStepForm, UserStepperState } from './contracts/forms'

export function fromUserApiSanitizer(profiles: ListUserApiResult['data']): ShortUser[] {
  return profiles.map((profile) => ({
    ...profile,
    firstName: profile.first_name,
    lastName: profile.last_name,
    allowedImageUseOnEdtech: profile.allowed_image_use_on_edtech,
    birthday: parseValidDateOrDefault(profile.birthday),
    organization: {
      id: profile.institution_id,
      code: profile.institution_code,
      name: profile.institution_name,
      address: profile.institution_address
    },
    role: {
      id: profile.role_id,
      code: profile.role_code,
      name: profile.role_name
    },
    roles: profile.roles.map((role) => ({
      idProfile: role.id_profile,
      idRole: role.id_role,
      name: role.name,
      code: role.code
    })),
    userId: profile.user_id
  }))
}

export function listShortStudentApiSanitizer(
  students: ListShortStudentApiResult['data']
): ListShortStudent[] {
  return students.map((student) => ({
    ...student,
    allowedImageUseOnEdtech: student.allowed_image_use_on_edtech,
    classrooms: student.classrooms.map((classroom) => ({
      ...classroom,
      enrollmentDate: classroom.enrollment_date
    }))
  }))
}

export function listTeacherApiSanitizer(teachers: ListTeacherApiResult['data']): Teacher[] {
  return teachers.map((teacher) => ({
    ...teacher,
    lastEnrollmentDate: teacher.last_enrollment_date,
    courses: teacher.courses.map((course) => ({
      ...course
    }))
  }))
}
export function showUserFormatSanitizer(profile: UserApi): ShortUserFormatted {
  const genderFound = genderDictionary[profile.gender ?? ''] as GenderDictionaryValue | undefined
  return {
    ...profile,
    firstName: profile.first_name,
    lastName: profile.last_name,
    allowedImageUseOnEdtech: profile.allowed_image_use_on_edtech,
    gender: genderFound?.key ?? genderDictionary.O.key,
    genderFormatted: genderFound?.value ?? genderDictionary.O.value,
    documentFormatted: cpfMask(profile.document),
    phoneFormatted: cellMask(profile.phone ?? ''),
    fullNameFormatted: `${profile.first_name} ${profile.last_name}`,
    birthday: parseValidDateOrDefault(profile.birthday),
    organization: {
      id: profile.institution_id,
      code: profile.institution_code,
      name: profile.institution_name,
      address: profile.institution_address
    },
    role: {
      id: profile.role_id,
      code: profile.role_code,
      name: profile.role_name
    },
    roles: profile.roles.map((role) => ({
      idProfile: role.id_profile,
      idRole: role.id_role,
      name: role.name,
      code: role.code
    })),
    enrollments: profile.enrollments,
    guardians: profile.guardians,
    userId: profile.user_id
  }
}

export function formUserFormatSanitizer(data: UserDataStepForm): UserDataStepForm {
  return {
    allowedImageUseOnEdtech: data.allowedImageUseOnEdtech,
    birthday: data.birthday,
    document: data.document ? removeMask(data.document) : null,
    email: data.email ? data.email : null,
    code: data.code,
    roles: data.roles,
    lastName: data.lastName,
    login: data.login,
    name: data.name,
    gender: data.gender ? data.gender : null,
    phone: data.phone ? data.phone : null,
    picture: data.picture ? data.picture : null,
    students: data.students
  }
}

export function formServiceSanitizer(
  data: UserStepperState['userData']
): Omit<CreateUserServiceParams, 'institutionId'> {
  const roles = data.form!.roles.map((role) => ({
    id: role
  }))
  const studentIds = data.students?.map((student) => ({
    id: student.id
  }))
  return {
    allowedImageUseOnEdtech: data.form!.allowedImageUseOnEdtech,
    birthday: convertDateToISO(data.form!.birthday),
    code: data.form!.code,
    document: data.form?.document ? removeMask(data.form.document) : null,
    email: data.form?.email ? data.form.email : null,
    firstName: data.form!.name,
    lastName: data.form!.lastName,
    gender: data.form?.gender ? data.form.gender : undefined,
    login: data.form!.login,
    phone: data.form?.phone ? removeMask(data.form.phone) : null,
    picture: data.form?.picture ? data.form.picture : null,
    roles,
    students: studentIds
  }
}

export function listQrCodeUsersApiSanitizer(
  ListQrCodeUsers: ListQrCodeUsersApiResult['data']
): QrCodeUsers[] {
  return ListQrCodeUsers.map((QrCodeUser) => ({
    ...QrCodeUser,
    classroomName: QrCodeUser.classroom_name
  }))
}

export function newPasswordApiSanitizer(
  newPassword: NewPasswordApiResult
): NewPasswordServiceResult {
  return {
    message: newPassword.message,
    newPassword: newPassword.new_password
  }
}
