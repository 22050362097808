import * as yup from 'yup'

import { i18n } from '@/common/i18n'

export const academicSync = yup.object().shape({
  schoolYearId: yup
    .string()
    .required(
      i18n().modules.hub.classroom.pages.form.stepper.classroomData.validators.requiredName
    ),
  data: yup
    .array()
    .of(yup.string().required())
    .required('Você precisa selecionar ao menos 1 tipo de dado.')
    .min(1, i18n().common.validators.min(1))
})
